/** @format */

import React from "react";
import { FaTwitter, FaReddit, FaTelegramPlane } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className='container-fluid pb-3'>
        <p className='text-center text-light fw-bold'>
          &copy; 2023 - Opium, All rights reserved.
        </p>

        <div className='flex justify-center space-x-4'>
          <a href='https://twitter.com/OpiumErc' target='_blank'  rel="noreferrer">
            <FaTwitter className='fs-4 fill-white' />
          </a>
          <a href='https://t.me/Opium_Erc' target='_blank'  rel="noreferrer">
            <FaTelegramPlane className='fs-4 fill-white' />
          </a>
          {/* <a href='#!' target='_blank'>
            <FaReddit className="fs-4 fill-white" />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
