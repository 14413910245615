/** @format */

import React from "react";
import Hero from "../../components/elements/Hero";
import About from "../../components/elements/About";
import Roadmap from "../../components/elements/Roadmap";
import HowToBuy from "../../components/elements/HowToBuy";
import Tokenomics from "../../components/elements/Tokenomics";
import Gallery from "../../components/elements/Gallery";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <About />
      <HowToBuy />
      <Tokenomics />
      <Roadmap />
      <Gallery />
    </React.Fragment>
  );
};

export default Home;
