/** @format */

import React from "react";

const Roadmap = () => {
  return (
    <div className='container roadmap py-12 md:py-20 border-t border-gray-800'>
      <h2 className='text-center'>Roadmap</h2>
      <div className='row mt-5 pt-4'>
        <div className='col-sm-4 '>
          <div className='phase-box p-3 text-center m-auto'>
            <p className=' text-gray-400 fw-bold'>Token Launch</p>
            <p className=' text-gray-400 fw-bold'>Website Launch</p>
            <p className=' text-gray-400 fw-bold'>1,0000 holders</p>
            <p className=' text-gray-400 fw-bold'>CG and CMC Listings</p>
            <p className=' text-gray-400 fw-bold'>$Opium</p>
          </div>
        </div>

        <div className='col-sm-4 '>
          <div className='phase-box p-3 text-center m-auto'>
            <p className=' text-gray-400 fw-bold'>Community Partnerships</p>
            <p className=' text-gray-400 fw-bold'>Exchange Listings</p>
            <p className=' text-gray-400 fw-bold'>
              Release Official Partnerships
            </p>
            <p className=' text-gray-400 fw-bold'>Further Digital Marketing</p>
            <p className=' text-gray-400 fw-bold'>5,000 Holders</p>
          </div>
        </div>

        <div className='col-sm-4 '>
          <div className='phase-box p-3 text-center m-auto'>
            <p className=' text-gray-400 fw-bold'>International Communities</p>
            <p className=' text-gray-400 fw-bold'>Website Revisions</p>
            <p className=' text-gray-400 fw-bold'>Meme Takeover</p>
            <p className=' text-gray-400 fw-bold'>$Opium Merchandise</p>
            <p className=' text-gray-400 fw-bold'>10,000 Holders</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
