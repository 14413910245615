/** @format */

import { useState } from "react";

const Timer = () => {
  const [days, setdays] = useState(null);
  const [hours, sethours] = useState(null);
  const [minutes, setminutes] = useState(null);
  const [seconds, setseconds] = useState(null);

  var countDownDate = new Date("Oct 26, 2023 18:30:00 UTC");
  var GetIme = new Date(countDownDate).getTime();

  setInterval(() => {
    var now = new Date().getTime();
    var distance = GetIme - now;
    var day = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hour = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    var minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var second = Math.floor((distance % (1000 * 60)) / 1000);
    setdays(day);
    sethours(hour);
    setminutes(minute);
    setseconds(second);
  }, 1000);
  return (
    <>
      {seconds > -1 && (
        <>
          <div className='d-flex flex-row justify-content-between align-content-center  text-white fw-bold'>
            Uniswap Live In 
          </div>
          <div
            style={{ display: "flex" }}
            className='timer-section w-100 text-center mt-2'>
            <h1 className='' id='hours'>
              <strong>{days} </strong>
              <small>Days</small>
            </h1>
            <h1 className='' id='hours'>
              <strong>{hours} </strong>
              <small>Hours</small>
            </h1>
            <h1 className='' id='minutes'>
              <strong>{minutes} </strong>
              <small>Minutes</small>
            </h1>
            <h1 className='' id='seconds'>
              <strong>{seconds} </strong>
              <small>Seconds</small>
            </h1>
          </div>
        </>
      )}
    </>
  );
};

export default Timer;
