/** @format */

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layout/Base";
import Home from "./pages/Home/Index";
import "aos/dist/aos.css";
import ReactGA from "react-ga";
import Aos from "aos";
ReactGA.initialize("G-KZMTS9LJB5");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  useEffect(() => {
    Aos.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1200, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom",
    });
  });
  return (
    <React.Fragment>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
