/** @format */

import React from "react";
import Timer from "../utils/Timer";

const Hero = () => {
  return (
    <React.Fragment>
      <div className='container hero pt-3 pb-5'>
        <div className='row'>
          <div className=' mx-auto text-center pb-12 md:pb-16 hero-content'>
            <div className=' text-center flex items-center mb-3'>
              <a
                href='https://app.uniswap.org/swap?outputCurrency=0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9&inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
                target='_blank'
                className='w-50'
                rel='noopener noreferrer'>
                <img
                  src='assets/Uniswap_Logo.png'
                  className='w-50   m-auto'
                  alt=''
                />
              </a>
              <a
                href='https://www.dexview.com/eth/0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9'
                target='_blank'
                className='w-50 '
                rel='noopener noreferrer'>
                <img src='assets/dexview.svg' className='w-50  m-auto' alt='' />
              </a>
            </div>
            <Timer />
            <hr className='text-gray-400 w-75 m-auto mt-3 mb-4' />
            <h1 data-aos='fade-up' className='aos-init aos-animate mb-3'>
              The exclusive gateway to your financial freedom.
            </h1>
            <p
              className='text-xl text-gray-400 mb-8 aos-init aos-animate'
              data-aos='fade-up'
              data-aos-delay='200'>
              Opium extends the promise of generational wealth to homeless
              individuals, granting the freedom to smoke and enjoy their life.
              #SaveHomeLess
            </p>
            <a
              href='https://etherscan.io/address/0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9'
              className='text-sm text-gray-400 mb-8 aos-init aos-animate'>
              CA: 0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9
            </a>
            <div className='max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center mt-4 '>
              <div
                data-aos='fade-up'
                data-aos-delay='400'
                className='aos-init aos-animate '>
                <a
                  className='btn-hero text-white bg-[#44ad00] hover:bg-[#44ad00ca] w-full mb-4 sm:w-auto sm:mb-0 '
                  href='https://blocksafu.com/audit/0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Audit
                </a>
              </div>
              <div
                data-aos='fade-up'
                data-aos-delay='400'
                className='aos-init aos-animate '>
                <a
                  className='btn-hero text-white bg-[#44ad00] hover:bg-[#44ad00ca] w-full mb-4 sm:w-auto sm:mb-0 sm:ml-4'
                  href='https://t.me/Opium_Erc'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Telegram
                </a>
              </div>

              <div
                data-aos='fade-up'
                data-aos-delay='600'
                className='aos-init aos-animate '>
                <a
                  className='btn-hero text-white bg-[#44ad00] hover:bg-[#44ad00ca] w-full mb-4 sm:w-auto sm:ml-4'
                  href='https://twitter.com/OpiumErc'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Twitter
                </a>
              </div>

              <div
                data-aos='fade-up'
                data-aos-delay='600'
                className='aos-init aos-animate '>
                <a
                  className='btn-hero text-white bg-[#44ad00] hover:bg-[#44ad00ca] w-full mb-4 sm:w-auto sm:ml-4 link-live'
                  href='https://app.uniswap.org/swap?outputCurrency=0x74BD4Cf06De0dfC233146139c7dddfc0A7447EE9&inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Uniswap
                </a>
              </div>

              <div
                data-aos='fade-up'
                data-aos-delay='600'
                className='aos-init aos-animate '>
                <a
                  className='btn-hero text-white bg-[#44ad00] hover:bg-[#44ad00ca] w-full mb-4 sm:w-auto sm:ml-4'
                  href='https://www.dextools.io/app/en/ether/pair-explorer/0xb67b48684b56b8f642846ec144f4146ec17a71eb'
                  target='_blank'
                  rel='noopener noreferrer'>
                  DexTools
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Hero;
