/** @format */

import React from "react";

const About = () => {
  return (
    <div className='container about  py-12 md:py-20 border-t border-gray-800'>
      <div className='row'>
        <div className='col-md-6 m-auto aos-init aos-animate' data-aos="fade-right">
          <h2>The Story of $Opium</h2>
          <p className='text-l text-gray-400 mb-4 mt-4'>
            In almost every city on the planet, people can be observed sleeping
            on the streets, on park benches or elsewhere. This is because
            homelessness affects people of all ages, sexes, races, and
            backgrounds, and is among the most obvious examples of poverty,
            prejudice, and inequality. In 2022, there were about 582,462
            homeless people living in the United States. Drug abuse is more
            common among younger homeless people. Opium holders embark on a
            journey of discovery. $Opium is a coin for those who are victims of
            drugs and getting out of it. Low Marketcap with fairlaunch, zero
            taxex, LP locked for year and contract renounced.
          </p>
        </div>
        <div className='col-md-6 m-auto aos-animate' data-aos="fade-up">
          <img src='assets/logo-cr.png' className='block m-auto' alt='' />
        </div>
      </div>
    </div>
  );
};

export default About;
