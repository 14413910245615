/** @format */

import React from "react";

const Gallery = () => {
  return (
    <div className='container-fluid mb-5'>
      <div className='row'>
        <div className='col-sm-3'>
          <div className='img-box ' data-aos="fade-up">
            <img src='assets/img1.webp' className="rounded-md img-fluid md:w-100" alt='' />
          </div>
        </div>
        <div className='col-sm-3'>
          <div className='img-box' data-aos="fade-down">
            <img src='assets/img2.jpg' className="rounded-md img-fluid md:w-100" alt='' />
          </div>
        </div>
        <div className='col-sm-3'>
          <div className='img-box h-[245px] overflow-hidden' data-aos="fade-up">
            <img src='assets/img3.jpeg' className="rounded-md img-fluid md:w-100" alt='' />
          </div>
        </div>
        <div className='col-sm-3'>
          <div className='img-box' data-aos="fade-down">
            <img src='assets/img4.jpg' className="rounded-md img-fluid md:w-100 " alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
