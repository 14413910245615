/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { SiGitbook, SiProbot } from "react-icons/si";
import { MdDashboard } from "react-icons/md";
function Header() {
  return (
    <header>
      <nav className='navbar navbar-expand-lg'>
        <div className='container'>
          <a className='navbar-brand' href='/'>
            <img src={"assets/logo.png"} width={80} alt='' />
          </a>

          <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
            <li className='nav-item m-auto'>
              <a
                className='nav-link tg'
                target='_blank'
                href='https://t.me/Opium_Erc'
                rel='noreferrer'>
                <button>Telegram</button>
              </a>
            </li>
            <li className='nav-item ml-5'>
              <a
                className='nav-link '
                rel='noreferrer'
                target='_blank'
                href='https://twitter.com/OpiumErc'>
                <button className='tw bg-[#44ad00] hover:bg-[#44ad00ca]'>
                  Twitter
                </button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
