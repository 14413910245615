/** @format */

import React from "react";

const HowToBuy = () => {
  return (
    <div className='container HowToBuy py-12 md:py-20 border-t border-gray-800'>
      <div className='row'>
        <h2>How to Buy</h2>
        <div className='col-md-12'>
          <div className='how-box py-6 mt-5 m-auto' data-aos="zoom-in-up">
            <div className='row'>
              <div className='col-sm-3 m-auto'>
                <img
                  src='assets/metamask.png'
                  className='w-50 d-block m-auto'
                  alt=''
                />
              </div>
              <div className='col-sm-9 m-auto'>
                <h4 className='text-2xl text-gray-200 fw-bold '>
                  Create a wallet
                </h4>
                <p className='text-xl text-gray-400'>
                  Download Metamask, or your wallet of choice, from the Apple
                  App Store or Google Play Store for free. Desktop users,
                  download the Google Chrome extension by going to metamask.io.
                </p>
              </div>
            </div>
          </div>

          <div className='how-box py-6 mt-5 m-auto' data-aos="zoom-in-up">
            <div className='row'>
              <div className='col-sm-3 m-auto'>
                <img
                  src='assets/eth.png'
                  className='w-50 d-block m-auto'
                  alt=''
                />
              </div>
              <div className='col-sm-9 m-auto'>
                <h4 className='text-2xl text-gray-200 fw-bold '>
                  Get some ETH
                </h4>
                <p className='text-xl text-gray-400'>
                  Have ETH in your wallet to switch to $Opium. If you don't have
                  any ETH, you can buy directly on Metamask, transfer from
                  another wallet, or buy on another exchange and send it to your
                  wallet.
                </p>
              </div>
            </div>
          </div>

          <div className='how-box py-6 mt-5 m-auto' data-aos="zoom-in-up">
            <div className='row'>
              <div className='col-sm-3 m-auto'>
                <img
                  src='assets/uniswap.png'
                  className='w-50 d-block m-auto'
                  alt=''
                />
              </div>
              <div className='col-sm-9 m-auto'>
                <h4 className='text-2xl text-gray-200 fw-bold '>
                  Go to Uniswap
                </h4>
                <p className='text-xl text-gray-400'>
                  Connect to Uniswap. Go to{" "}
                  <a
                    href='http://app.uniswap.org/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-sky-300'>
                    app.uniswap.org
                  </a>{" "}
                  in your web browser, or on the browser inside of the Metamask
                  app. Connect your wallet. Paste the $Opium token address into
                  Uniswap, select Opium, and confirm. When Metamask prompts you
                  for a wallet signature, sign.
                </p>
              </div>
            </div>
          </div>

          <div className='how-box py-6 mt-5 m-auto' data-aos="zoom-in-up">
            <div className='row'>
              <div className='col-sm-3 m-auto'>
                <img
                  src='assets/swap.png'
                  className='w-50 d-block m-auto'
                  alt=''
                />
              </div>
              <div className='col-sm-9 m-auto'>
                <h4 className='text-2xl text-gray-200 fw-bold '>
                  Swap ETH for Opium
                </h4>
                <p className='text-xl text-gray-400'>
                  Switch ETH for $Opium. We have ZERO taxes so you don't need to
                  worry about buying with a specific slippage, although you may
                  need to use slippage during times of market volatility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
