/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <div className='container tokenomics py-12 md:py-20 border-t border-gray-800'>
        <h2>Tokenomics</h2>
      <div className='row text-center mt-5'>
        <div className='col-sm-4'>
          <div>
            <h1>0%</h1>
            <p className='text-xl text-gray-400 mt-3'>BUY TAX</p>
          </div>
        </div>
        <div className='col-sm-4'>
          <div>
            <h1>420,000,000</h1>
            <p className='text-xl text-gray-400 mt-3'>TOTAl SUPPLY</p>
          </div>
        </div>
        <div className='col-sm-4'>
          <div>
            <h1>0%</h1>
            <p className='text-xl text-gray-400 mt-3'>SELL TAX</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
